.mb-1 {
    margin-bottom: .25rem;
}

.mt-2 {
    margin-top: .5rem;
}

.d-flex {
    display: flex;
}

.mr-2 {
    margin-right: .5rem;
}

.card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    text-align: start;
}

.card-body {
    flex: 1 1 auto;
}

.mb-slider-img {
    flex-grow:0;
    flex-shrink:0;
    flex-basis:200px;
    background-size: cover;
    background-position: center center;
}

.mb-slider .card{
    height:180px;
    overflow:hidden;
}

  .mb-slider {
      height: 180px;
}

.fadeIn-appear {
    opacity: 0;
    position: relative;
    bottom: -10px;
}

.fadeIn-appear.fadeIn-appear-active {
    /* opacity: 0.3; */
    /* transition: all 1s ease-in-out; */
    opacity: 0;
}

.fadeIn-enter {
    /* opacity: 0.6; */
    opacity: 0;
    position: relative;
}

.fadeIn-enter.fadeIn-enter-active {
    opacity: 1;
    transition: all .5s ease-in-out;
}

.fadeIn-enter-done {    
    /* transition-duration: 300ms; */
    transition: all .5s ease-in-out;
}