.form-register .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-register .checkbox {
    font-weight: 400;
}

.react-tel-input .form-control {
    padding-left: 48px!important;
}