.sidebar .LeftNavigationBar {    
    top: 25;
    height: calc(100vh-48px);    
    overflow-x: hidden;
    overflow-y: auto;
    /* background-color: #181a1d; */
    text-align: left;
    /* padding: 20px; */
    color: #6c757d!important;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0,0,0,.1);
    min-width: 150px;
    background-color: #181a1d;
}

.mb-sidebar {
    width: 220px;
}

.sidebar-heading {
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 1rem!important;
    padding-right: 1rem!important;
    margin-top: 1.5rem!important;    
    justify-content: space-between!important;
    display: flex!important;
    align-items: center!important;
}

.sidebar a {
    display: inline-block;
    color: #6c757d;
    padding-right: 0;
}

.nav-link {
    display: block;
    padding: .5rem 1rem; 
    
}

.link-title{
    font-size: 14px;
    font-weight: 500;
    margin-left: -5px;
}

.sidebar a:hover{
    color: #8d969e;
}

.headshot{
    border-radius:300px;
    max-width:100px;
}

@media (max-width: 768px){
    .sidebar {
        display: none;
    }
}

/* .LeftNavigationBar .ContactModal {
    width: 100%;
    font: black!important;
    font-size: 25px!important;
} */

.contact-modal .Modal {
    max-width: 300px;
    font-size: 14px;
    color: black!important; 
}

.nav-link.active-nav {
    color: #007bff;
}

.nav-link.active-nav:hover {
    color: #007bff;
}

.left-nav-modal {
    position: fixed;
    width: 10%;
}