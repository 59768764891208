.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    font-size: inherit;
    line-height: 1.5;
}

@media(min-width: 576px) {
    .form-inline-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .mb-lp-card {
        width: 100%!important;
    }
}