.custom-control-label {
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
}

label {
    display: inline-block;
}

.mb-radio {
    display: inline-block;
    background: white;
    border-radius: 4px;
    padding: 4px 0px 2px 5px;
    border: 1px solid RGBA(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 2px;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}