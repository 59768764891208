.Content {
    margin-left: 220px;
    /* overflow: visible; */
    /* display: inline-block; */
    /* clear: both; */
    /* width: 100%; */
    /* max-width: calc(100%-450px); */
    padding-left: .5rem!important;
    padding-right: .5rem!important;
    font-size: 14px;
    margin-right: 230px;
    margin-top: 50px;
}

@media (max-width: 1200px){
    .Content {
        margin-right: 0;
    }
}

@media(max-width: 768px){
    .Content {
        margin-top: 50px;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
    }
}