.mb-traffic {
    padding: 2px 5px;
    margin: 3px 2px;
    display: inline-block;
    cursor: default;
}

.mb-traffic.allowed {
    color: #17a2b8;
    border: 1px solid #17a2b8;
    border-radius: 50px;
}

.mb-traffic.not-allowed {
    color: #6c757d;
    text-decoration: line-through;
}