.Banner .card-body {
    padding: 1.25rem!important;
}

.Banner .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.max-width-250 {
    max-width: 250px;
}

@media(max-width: 576px) {
    .Banner img {
        padding: 0;
        size: 100px!important;
    }
}

@media(min-width: 992px){
    .modal-lg{
        max-width: 800px;
    }
}