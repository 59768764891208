.byrequest-form label{
    font-weight:bold;
    display:block;
}

.byrequest-form input[type="text"]{
    max-width:300px;
}

.byrequest-form input[type="text"].long{
    max-width:500px;
}

.byrequest-form input[type="text"].short{
    max-width:100px;
}

.byrequest-form select{
    max-width:250px;
}