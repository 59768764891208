.terms-header{
    margin-left:18.0pt;
    text-indent:-18.0pt;
}

.terms-item{
    margin-left:54.0pt;
    text-indent:-25.65pt;
}

.terms-sub-item{
    margin-left:81.0pt;
    text-indent:-36.0pt;
}

.terms-container{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    z-index:9999 !important;
}