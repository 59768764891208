.table.table-card tbody tr .no-border {
    border: none;
}

.table td, .table th {
    padding: .5rem!important;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-feature-settings: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: inherit;
}

.Search .ui-autocomplete {
    position: absolute;
    z-index: 100;
    width: 520px;    
}