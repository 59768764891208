.survey-message-success {
    background: #00d961;
    padding: 10px;
    /* padding: 10px!important; */
    margin: 20px;
    color: white;
    border-radius: 3px;
    text-align: left;
    font-size: 14px;
}

.survey-message-text {
    margin-left: 20px;
}

.dropdown-menu {
    max-height: 400px;
    overflow-y: scroll;
}

.dropdown-label:hover {
    cursor: pointer;
    background-color: lightgrey;
}

.IntroModal input[type=checkbox]:checked + label{
    background-color: #337ab7;
    color: white;
    width: 90%;
}

.no-values-selected {
    background-color: rgb(255, 180, 180);
}