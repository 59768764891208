.Campaigncard {
    flex: 1 0 11rem;
    /* flex: 1 0 11rem; */
    margin: 3px;
    display: flex;
}

.mb-card-img-top {
    width: 100%;
    height: 125px;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    background-size: cover;
    background-position: center center;
}

.mb-campaign-price {
    border: 1px solid #0089fe;
    padding: 3px;
    border-radius: 4px;
    display: inline-block;
    color: #0089fe;
    font-size: .8rem;
}

.Campaigncard .card-body {
    padding: .5rem!important;
    font-size: .75rem;
}

.mb-card {
    position: relative;
    /* display: flex; */
    flex-direction: column;
    width: 100%;
    min-width: 0px;
    max-width: 320px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.transparent{
    opacity:0;
}

.fade-appear {
    opacity: 0;
    position: relative;
    bottom: -10px;
}

.fade-appear.fade-appear-active {
    /* opacity: 0.3; */
    /* transition: all 1s ease-in-out; */
    opacity: 0;
    bottom:0px;
}

.fade-enter {
    /* opacity: 0.6; */
    opacity: 0;
    position: relative;
    bottom: -10px;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    bottom: 0px;
    transition: all .5s ease-in-out;
}

.fade-enter-done {    
    /* transition-duration: 300ms; */
    transition: all .5s ease-in-out;
}

.highlighted{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .40);
    border:1px solid black;
}