.register-container{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

div.signup-container{
    height:100%;
}

.form-register {
    width: 100%;
    max-width: 500px;
    padding: 50px 15px 15px 15px;
    margin: auto;
    z-index:9999 !important;
}

.login-video{
position: fixed;
right: 0;
bottom: 0;
min-width: 100%;
min-height: 100%;
}

.z-99{
    z-index:99999;
}

.left-register{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#12171f+0,07080e+100 */
    background: #12171f; /* Old browsers */
    background: -moz-linear-gradient(45deg, #12171f 0%, #07080e 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #12171f 0%,#07080e 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #12171f 0%,#07080e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12171f', endColorstr='#07080e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    display:flex;
    -webkit-display:flex;
    color:white;
    position:fixed !important;
    height:100%;
}

.right-register{
    display:flex;
    -webkit-display:flex;
    margin-left:40%;
}

.partner-logo{
    max-width:250px;
    width:100%;
    margin-bottom:2rem;
}

.mktg-pop{
    position:absolute;
    z-index: 9999999;
    top: 10px;
    margin-left:auto;
    margin-right:auto;
    left:0;
    right:0;
    text-align:center;
}

@media only screen and (max-height: 850px), screen and (max-width: 1500px){
    .mktg-icons{
        display:none !important;
    }
}

@media only screen and (max-width: 768px){
    .left-register{
        display:none !important;
    }
    .right-register{
        margin:auto;
    }
    .form-register {
        width: 100%;
        max-width: 500px;
        padding: 0px;
        margin: auto;
        z-index:9999 !important;
    }
}