.mb-lp-card.active {
    border: 1px solid #007bff;
}

.mb-lp-card {
    flex: 1 1 11rem;
    margin: 3px;
}

.mb-lp-image {
    height: 100px;
    background-size: cover;
    background-position: center center;
}

a {
    color: #007bff;
    text-decoration: none;
}

@media(min-width: 576px){
    .mb-lp-card {
        width: 100%!important;
    }
}
