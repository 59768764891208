.RightSidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    padding: 48px 0 0 7px;
    box-shadow: inset -1px 0 0 rgba(0,0,0,.1);
    min-width: 150px;
    background: white;
    width: 230px;
    margin-top: .5rem;
    font-weight: 400;
    overflow-y: scroll;
    padding-bottom: 0.5rem;
}

/* Adding this to make sidebar invisible below 1200px screen width - TEMP FIX */
.RightSidebar:hover{
    overflow-y: scroll;
}

@media (max-width: 1200px){
    .RightSidebar {
        display: none;
    }
}