.Macro {
    font-size: 14px;
}

pre {
    display: block;
    /* font-size: 13px!important; */
    padding: 9.5px;
    margin: 0 0 10px;
    line-height: 1.43;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #ccc;
    border-radius: 4px;
}