.maxmoney-gold{
    font-weight:bold;
	color:#d0a85b;
	background: -webkit-linear-gradient(#fff, #b0842e);
	background-clip: text;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.maxmoney-event{
	border-radius:3px;
	border:1px solid RGBA(255,255,255,0.1);
	margin:10px 0px;
	background-color:RGBA(0,0,0,0.1);
}

.maxmoney-event-date{
	padding:20px;
	background-color:RGBA(255,255,255,0.05);
    min-width:150px;
    text-align:center;
}

.maxmoney-item{
	padding:10px;
	border-left:1px solid RGBA(255,255,255,0.1);
	flex:1;
}

.maxmoney-promo-earnings{
	text-align:center;
	padding:0px 25px;
	border-left:1px solid RGBA(255,255,255,0.05);
	border-right:1px solid RGBA(255,255,255,0.05);
}

.maxmoney-optin{
	padding:10px;
	justify-content: flex-end;
}

.maxmoney-promo-symbol{
    max-width:15px;
    margin-top:-3px;
}